@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  word-break: break-all;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @deprecated */
input.MuiInputBase-input {
  font-size: 14px;
  background-color:"#FFFFFF";
}

/* @deprecated */
input.MuiInputBase-input::placeholder{
  color: "#757A80";
  opacity: 1;
}
/* @deprecated */
table * {
  font-family: "SF UI Display", "-apple-system", "BlinkMacSystemFont", "Roboto", "Noto Sans JP", "Segoe UI", "Hiragino Kaku Gothic ProN", "YuGothic", "Meiryo", sans-serif !important;
}

/* @deprecated */
.MuiChip-colorPrimary {
  background-color: "#048CF2";
}

/* @deprecated */
.MuiSelect-select {
  font-size: 14px !important;
  color: rgb(117, 122, 128) !important;
}
